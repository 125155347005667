import React, { useState, useEffect, useRef} from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import '../App.css';
import $ from "jquery";
// import Lottie from 'react-lottie';
import animationData from '../json/email_success.json';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import LoginModal from './modal/LoginModal';
import ReCAPTCHA from 'react-google-recaptcha';
import apiService from '../services/apiService';
import CustomToast from './components/Toast';

$(document).ready(function () {
  $('.testimonial-slider').slick({
    autoplay: true,
    autoplaySpeed: 1000,
    speed: 600,
    draggable: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  });
});

const PAGE_SIZE = 6;

const newsData = [
  { "id": 1, "imgurl": "/images/RAI-ARTICLE-1.jpg", "captionUrl": "https://www.linkedin.com/posts/retailers-association_rai-x-inclusional-mou-sign-post-activity-7163093413653823489-irdO?utm_source=share&utm_medium=member_desktop", "caption": "LinkedIn | Retailers Association of India (RAI)", "description": "Retailers Association of India (RAI) takes a significant stride towards enhancing workplace safety and inclusivity by joining forces with Inclusional" },
  { "id": 2, "imgurl": "/images/passionate-in-marketing.png", "captionUrl": "https://www.passionateinmarketing.com/inclusional-jet-social-impact-jetsynthesys-posh-knowledge-partners-of-the-national-commission-for-women-ncw-successfully-completes-its-flagship-workshop-for-corporates-on-posh-beyond-com/#google_vignette", "caption": "Article | Passionate in marketing","description": "Inclusional, POSH Knowledge Partners Of NCW Successfully Completes Its Flagship Workshop For Corporates On ‘POSH Beyond Compliance’" },
  { "id": 3, "imgurl": "/images/Rajan-Navani.png", "captionUrl": "https://www.linkedin.com/posts/rajan-navani_posh-awareness-workshop-by-jetsynthesys-activity-7159156648035418112-6z_3?utm_source=share&utm_medium=member_desktop", "caption": "LinkedIn | Rajan Navani","description": "Rajan Navani, Vice Chairman & MD at JetLine Group of Companies; Founder & CEO JetSynthesys shares his thoughts on Inclusional" },
  { "id": 4, "imgurl": "/images/RAI.png", "captionUrl": "https://www.linkedin.com/feed/update/urn:li:activity:7165608544812183552", "caption": "Article | Retailers Association of India (RAI)","description": "Psychological safety in the retail ecosystem" },
  { "id": 5, "imgurl": "/images/BW-people.png","captionUrl": "https://bwpeople.businessworld.in/article/POSH-Beyond-Gender-How-India-Inc-Woefully-Missed-The-Upside/09-09-2023-490627/", "caption": "Article | BW People","description": "POSH Beyond Gender: How India Inc Woefully Missed The Upside" },
  { "id": 6, "imgurl": "/images/people-matters.png", "captionUrl": "https://www.peoplematters.in/article/training/unlocking-potential-elevating-ld-with-posh-for-employee-growth-39327", "caption": "Article | People Matters","description": "Unlocking potential: Elevating L&D with POSH for employee growth" },
  { "id": 7, "imgurl": "/images/posh-reimagined.png", "captionUrl": "https://www.peoplematters.in/article/training/unlocking-potential-elevating-ld-with-posh-for-employee-growth-39327", "caption": "Youtube | Inclusional", "description": "POSH awareness workshop for the Corporate Sector - Ahmedabad Highlights" },
  { "id": 8, "imgurl": "/images/posh-reimagined-2.png", "captionUrl": "https://www.passionateinmarketing.com/inclusional-jet-social-impact-jetsynthesys-posh-knowledge-partners-of-the-national-commission-for-women-ncw-successfully-completes-its-flagship-workshop-for-corporates-on-posh-beyond-com/#google_vignette", "caption": "Youtube | Inclusional","description": "POSH awareness workshop for the Corporate Sector - Pune Highlights" },
  { "id": 9, "imgurl": "/images/posh-awareness-workshop.png", "captionUrl": "https://youtu.be/gstsLSw4aDU", "caption": "Youtube | Inclusional","description": "POSH awareness workshop for the Corporate Sector - Bengaluru Highlights" },
  { "id": 10, "imgurl": "/images/National-Commission-for-Women.png", "captionUrl": "https://youtu.be/vBn5JZUKBd8?si=X5gh36jsDt4WTa7Y", "caption": "Youtube | National Commission for Women","description": "National Commission for Women - Prevention of Sexual Harassment at Workplace Awareness Series"},
  { "id": 11, "imgurl": "/images/National-Commission-for-Women-1.png", "captionUrl": "https://twitter.com/NCWIndia/status/1733073072280998287?s=20", "caption": "Twitter | National Commission for Women", "description": "National Commission for Women - Prevention of Sexual Harassment at Workplace Awareness Series"},
  { "id": 12, "imgurl": "/images/National-Commission-for-Women-3.png", "captionUrl": "https://youtu.be/vKN1y8ZClLg?si=TwwaQqkYZDjafqT-", "caption": "Youtube | National Commission for Women", "description": "National Commission for Women - Prevention of Sexual Harassment at Workplace Awareness Serie"},
]

// $(window).on('scroll', function () {
//   if ($(window).scrollTop()) {
//     $('nav').addClass('black');
//   }
//   else {
//     $('nav').removeClass('black');
//   }
// })

$(window).scroll(function () {
  var windscroll = $(window).scrollTop();
  if (windscroll >= 100) {
    $('section').each(function (i) {
      // The number at the end of the next line is how pany pixels you from the top you want it to activate.
      if ($(this).position().top <= windscroll - 0) {
        $('nav li.active').removeClass('active');
        $('nav li').eq(i).addClass('active');
      }
    });
  } else {
    $('nav li.active').removeClass('active');
    $('nav li:first').addClass('active');
  }
}).scroll();


const Home = () => {

  const [name, setName] = useState('');
  const [mobile_no, setMobileNo] = useState('');
  const [email, setEmail] = useState('');
  const [organisation, setOrganisation] = useState('');
  const [message, setMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [showOTP, setShowOTP] = useState(false);
  const recaptchaRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
   const params = new URLSearchParams(location.search);
   const token = params.get('token');

   if (token) {
     openLoginModal();
   }
  }, []);

  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
    setShowOTP(false)
  };

  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * PAGE_SIZE;
  const endIndex = startIndex + PAGE_SIZE;
  const currentImages = newsData.slice(startIndex, endIndex);

  const totalPages = Math.ceil(newsData.length / PAGE_SIZE);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };
  function onChange(value) {
    console.log('Captcha value:', value);
  }

  // useEffect(() => {
  //   // Initialize your functionality
  //   init();

  //   // Bind the wheel event listener
  //   window.addEventListener('wheel', wheelHandler);

  //   // Cleanup the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('wheel', wheelHandler);
  //   };
  // }, []);

  // function init() {
  //   setStickyContainersSize();
  // }

  // function setStickyContainersSize() {
  //   document.querySelectorAll('.sticky-container').forEach((container) => {
  //     const stickyContainerHeight = container.querySelector('main').scrollWidth;
  //     container.setAttribute('style', `height: ${stickyContainerHeight}px`);
  //   });
  // }

  function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return rect.top <= 0 && rect.bottom > document.documentElement.clientHeight;
  }

  function wheelHandler(evt) {
    const containerInViewPort = Array.from(document.querySelectorAll('.sticky-container')).find(
      (container) => isElementInViewport(container)
    );

    if (!containerInViewPort) {
      return;
    }

    const isPlaceHolderBelowTop = containerInViewPort.offsetTop < document.documentElement.scrollTop;
    const isPlaceHolderBelowBottom =
      containerInViewPort.offsetTop + containerInViewPort.offsetHeight > document.documentElement.scrollTop;
    const canScrollHorizontally = isPlaceHolderBelowTop && isPlaceHolderBelowBottom;

    if (canScrollHorizontally) {
      containerInViewPort.querySelector('main').scrollLeft += evt.deltaY;
    }
  }

  const defaultOptions = {
    loop: true, // Set to true for the animation to loop
    autoplay: true, // Set to true for the animation to start automatically
    animationData: animationData, // Your imported animation JSON
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  // added the code log

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    validateEmail(newEmail);
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailPattern.test(email);
    if (!isValid) {
      setEmailError('Please enter a valid email address.');
    } else {
      setEmailError('');
    }
  };

  const handleMobileChange = (e) => {
    const newMobileNumber = e.target.value;
    setMobileNo(newMobileNumber);
    validateMobile(newMobileNumber);
  };

  const validateMobile = (mobile_no) => {
    const mobilePattern = /^[0-9]{10}$/;
    const isValid = mobilePattern.test(mobile_no);
    if (!isValid) {
      setMobileError('Please enter a valid 10-digit phone number.');
    } else {
      setMobileError('');
    }
  };

  const handleSend = async(event) => {
    event.preventDefault();
    const recaptchaValue = await recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      CustomToast.error('Please complete the reCAPTCHA verification.')
    } else {
      if (!emailError && email && !mobileError && mobile_no) {
         // Verify reCAPTCHA
         const recaptchaResult = await apiService.post('verify-recaptcha', { recaptchaResponse: recaptchaValue });
         if (recaptchaResult.status != "success") {
           CustomToast.error('Failed to verify reCAPTCHA. Please try again.')
           return;
         }
        let payload = {
          "name": name,
          "mobile": mobile_no,
          "email": email,
          "organisation": organisation,
          "message": message,
        }
        const headers = {
          'Content-Type': 'application/json',
          "app-key": process.env.REACT_APP_API_KEY,
        };
        axios.post(`${process.env.REACT_APP_ADMIN_API_URL}/api/v2/org/save-contact-us-data`, payload, { headers })
          .then((r) => {
          //  console.log("result", r);
            setName('');
            setMobileNo('');
            setEmail('');
            setOrganisation('');
            setMessage('');
            setShowSuccess(true);
          })
          .catch(err => {
            console.log(err);
          })
      }
    }
  }

  const handleClick = () => {
    setShowSuccess(false);
  };

  return (
    <div className="App">

      <nav class="navbar navbar-expand-lg fixed-top flex-wrap pb-0">
        <div class="container pb-1">
          <a class="navbar-brand" href="#">
              <img src="/images/logo.png" alt="logo" className='logo-main' />
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav d-flex align-items-lg-center align-items-end ms-auto">
              <li class="nav-item active">
                <a class="nav-link" aria-current="page" href="#">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#about">About Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#offer">Offerings</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#contact">Contact Us</a>
              </li>
              <li class="nav-item">
                <a class="nav-link">
                  <button type='button' className='btn btn-primary' onClick={openLoginModal}>Sign In</button>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div style={{ width: '100%', position: 'relative', overflow: 'hidden', marginBottom: '-60px'}}>
          <marquee style={{ backgroundColor: '#146B90', padding: '4px 0' }}>
            <p className='text-white fw-semibold font-16 mb-0 py-2'>
              <span className='marquee-content'>
                <span className='me-2'>
                  <img src='/images/NCW-logo.svg' width={32} height={30} alt='NCW logo' />
                </span>
                Knowledge Partner for the National Commission for Women (NCW) on POSH </span>
              <span> <span className='me-2'>
                <img src='/images/NCW-logo.svg' width={32} height={30} alt='NCW logo' />
              </span> Knowledge Partner for the National Commission for Women (NCW) on POSH</span>
            </p>
          </marquee>
          {/* <div className='shine-element'></div> */}
        </div>
      </nav>

        <LoginModal
          isOpen={isLoginModalOpen}
          onRequestClose={closeLoginModal}
          isShowOTP={showOTP}
          
        />

      {/* <div className="responsive-bar">
        <div className="logo">
          <img src="/images/logo.png" alt="logo" />
        </div>
        <div className="menu">
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <img src="/images/menu.svg"></img>
          </button>
        </div>
      </div> */}

      {/* <div className="container">
        <nav className=' navbar-fixed-top'>
          <div className="logo">
            <img src="/images/logo.png" alt="logo" className='logo-main' />
          </div>

          <div className="mb-logo">
            <img src="/images/logo_mb.png" alt="logo" className='logo-overlap' />
          </div>

          <ul>
            <li className="active"><a href="#">Home</a></li>
            <li><a href="#about">About Us</a></li>
            <li><a href="#offer">Offerings</a></li>
            <li><a href="#contact">Contact Us</a></li>
            <li><button type='button' className='btn btn-primary' onClick={openLoginModal}>Sign In</button></li>
          </ul>
        </nav>
       
        <LoginModal
          isOpen={isLoginModalOpen}
          onRequestClose={closeLoginModal}
          isShowOTP={showOTP}
          
        />
 
      </div> */}

      {/* <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img src="/images/logo.png" className="logo"></img>
          </a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <img src="/images/menu.svg"></img>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto w-100 justify-content-end">
              <li className="nav-item active">
                <a className="nav-link red" href="#">Home</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#about">About Us</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#offer">Offerings</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}

      <div className=''>

        <section className='sticky-container home'>
        <main>
          <div id="demo" class="carousel slide" data-bs-ride="carousel" data-interval="10000">

            <div class="carousel-indicators top-indicators">
              <button type="button" data-bs-target="#demo" data-bs-slide-to="0" class="active"></button>
              <button type="button" data-bs-target="#demo" data-bs-slide-to="1"></button>
              <button type="button" data-bs-target="#demo" data-bs-slide-to="2"></button>
            </div>

            <div class="carousel-inner">
              <div class="carousel-item active">
                  <div class='offering-section'>
                    <div class='container'>
                      <div class='row'>
                        <div class='col-md-6 mobile-display'>
                          <img src="/images/banner_1.svg" class='home-images' />
                        </div>
                        <div class='col-md-6'>
                          <p class='home-head'><span class='home-head-highlight-1'>Diversity .</span> Inclusion . Belonging</p>
                          <p class='active-diver'></p>
                          <h5 class='home-title'>We operate in a <span class='home-highlight-1'>DIVERSE</span> world! Diverse people, diverse ideas, diverse solutions.</h5>
                          <p class='home-subtitle'>At Inclusional, we help you integrate diversity into business.</p>
                        </div>
                        <div class='col-md-6 mobile-none'>
                          <img src="/images/banner_1.svg" class='banner_1 home-images' />
                        </div>
                      </div>
                    </div>
                  </div> 
              </div>

              <div class="carousel-item">
                <div class='offering-section'>
                  <div class='container'>
                    <div class='row'>
                      <div class='col-md-6 mobile-display'>
                        <img src="/images/banner_2.svg" class='home-images' />
                      </div>
                      <div class='col-md-6'>
                        <p class='home-head'>Diversity .<span class='home-head-highlight-1'> Inclusion .</span> Belonging</p>
                        <p class='active-inclu'></p>
                        <h5 class='home-title'><span class='home-highlight-2'>INCLUSION</span> of diverse people, ideas and solutions leads to endless opportunities. </h5>
                        <p class='home-subtitle'>At Inclusional, we work with you to include more effectively, so you can grow efficiently and sustainably. </p>
                      </div>
                      <div class='col-md-6 mobile-none'>
                        <img src="/images/banner_2.svg" class='banner_2 home-images' />
                      </div>
                    </div>
                  </div>
                </div>  
              </div>

              <div class="carousel-item">
                  <div class='offering-section'>
                    <div class='container'>
                      <div class='row'>
                        <div class='col-md-6 mobile-display'>
                          <img src="/images/banner_3.svg" class='home-images' />

                        </div>
                        <div class='col-md-6'>
                          <p class='home-head'>Diversity . Inclusion . <span class='home-head-highlight-1'>Belonging</span></p>
                          <p class='active-belon'></p>
                          <h5 class='home-title'>
                          When diversity is truly included, <span class='home-highlight-3'>BELONGING</span> begins, creating something bigger, and the whole ecosystem thrives.
                          </h5>
                          <p class='home-subtitle'>At Inclusional, we facilitate belonging, so people, businesses, and economies prosper.</p>
                        </div>
                        <div class='col-md-6 mobile-none'>
                          <img src="/images/banner_3.svg" class='home-images' />
                        </div>
                      </div>
                    </div>    
                  </div>
              </div>
            </div>

            <button class="carousel-control-prev black-color" type="button" data-bs-target="#demo" data-bs-slide="prev">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
            </button>
            <button class="carousel-control-next black-color" type="button" data-bs-target="#demo" data-bs-slide="next">
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
            </button>
          </div>

          {/* <div className="home-banner-container">
            <div className="description panel">
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6 mobile-display'>
                    <img src="/images/banner_1.svg" className='home-images'></img>
                  </div>
                  <div className='col-md-6'>
                    <p className='home-head'><span className='home-head-highlight-1'>Diversity .</span> Inclusion . Belonging</p>
                    <p className='active-diver'></p>
                    <h5 className='home-title'>We operate in a <span className='home-highlight-1'>DIVERSE</span> world! <br></br>Diverse people, diverse ideas,<br></br> diverse solutions.</h5>
                    <p className='home-subtitle'>At Inclusional, we help you integrate diversity into business.</p>
                  </div>
                  <div className='col-md-6 mobile-none'>
                    <img src="/images/banner_1.svg" className='banner_1 home-images'></img>
                  </div>
                </div>
              </div>
            </div>
            <div className="panel">
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6 mobile-display'>
                    <img src="/images/banner_2.svg" className='home-images'></img>
                  </div>
                  <div className='col-md-6'>
                    <p className='home-head'>Diversity .<span className='home-head-highlight-1'> Inclusion .</span> Belonging</p>
                    <p className='active-inclu'></p>
                    <h5 className='home-title'><span className='home-highlight-2'>INCLUSION</span> of diverse people, ideas and solutions leads to endless opportunities. </h5>
                    <p className='home-subtitle'>At Inclusional, we work with you to include more effectively, so you can grow efficiently and sustainably. </p>
                  </div>
                  <div className='col-md-6 mobile-none'>
                    <img src="/images/banner_2.svg" className='banner_2 home-images'></img>
                  </div>
                </div>
              </div>

            </div>
            <div className="panel">
                  <div className='container'>
                    <div className='row'>
                      <div className='col-md-6 mobile-display'>
                        <img src="/images/banner_3.svg" className='home-images'></img>
                      </div>
                      <div className='col-md-6'>
                        <p className='home-head'>Diversity . Inclusion . <span className='home-head-highlight-1'>Belonging</span></p>
                        <p className='active-belon'></p>
                        <h5 className='home-title'>
                        When diversity is truly included, <span className='home-highlight-3'>BELONGING</span> begins, creating something bigger, and the whole ecosystem thrives.
                        </h5>
                        <p className='home-subtitle'>At Inclusional, we facilitate belonging, so people, businesses, and economies prosper.</p>
                      </div>
                      <div className='col-md-6 mobile-none'>
                        <img src="/images/banner_3.svg" className='home-images'></img>
                      </div>
                    </div>
                  </div>
            </div>
          </div> */}




          {/* <main>
            <div className='offering-section'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6 mobile-display'>
                    <img src="/images/banner_1.svg" className='home-images'></img>
                  </div>
                  <div className='col-md-6'>
                    <p className='home-head'><span className='home-head-highlight-1'>Diversity .</span> Inclusion . Belonging</p>
                    <p className='active-diver'></p>
                    <h5 className='home-title'>We operate in a <span className='home-highlight-1'>DIVERSE</span> world! <br></br>Diverse people, diverse ideas,<br></br> diverse solutions.</h5>
                    <p className='home-subtitle'>At Inclusional, we help you integrate diversity into business.</p>
                  </div>
                  <div className='col-md-6 mobile-none'>
                    <img src="/images/banner_1.svg" className='banner_1 home-images'></img>
                  </div>
                </div>
              </div>
            </div>
            <div className='offering-section'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6 mobile-display'>
                    <img src="/images/banner_2.svg" className='home-images'></img>
                  </div>
                  <div className='col-md-6'>
                    <p className='home-head'>Diversity .<span className='home-head-highlight-1'> Inclusion .</span> Belonging</p>
                    <p className='active-inclu'></p>
                    <h5 className='home-title'><span className='home-highlight-2'>INCLUSION</span> of diverse people, ideas and solutions leads to endless opportunities. </h5>
                    <p className='home-subtitle'>At Inclusional, we work with you to include more effectively, so you can grow efficiently and sustainably. </p>
                  </div>
                  <div className='col-md-6 mobile-none'>
                    <img src="/images/banner_2.svg" className='banner_2 home-images'></img>
                  </div>
                </div>
              </div>
            </div>
            <div className='offering-section'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6 mobile-display'>
                    <img src="/images/banner_3.svg" className='home-images'></img>
                  </div>
                  <div className='col-md-6'>
                    <p className='home-head'>Diversity . Inclusion . <span className='home-head-highlight-1'>Belonging</span></p>
                    <p className='active-belon'></p>
                    <h5 className='home-title'>
                      When diversity is truly included, <span className='home-highlight-3'>BELONGING</span> begins, creating something bigger, and the whole ecosystem thrives.
                    </h5>
                    <p className='home-subtitle'>At Inclusional, we facilitate belonging, so people, businesses, and economies prosper.</p>
                  </div>
                  <div className='col-md-6 mobile-none'>
                    <img src="/images/banner_3.svg" className='home-images'></img>
                  </div>
                </div>
              </div>
            </div>
          </main> */}

          {/* <div id="demo" className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-6 mobile-display'>
                      <img src="/images/banner_1.svg" className='home-images'></img>
                    </div>
                    <div className='col-md-6'>
                      <p className='home-head'><span className='home-head-highlight-1'>Diversity .</span> Inclusion . Belonging</p>
                      <p className='active-diver'></p>
                      <h5 className='home-title'>We operate in a <span className='home-highlight-1'>DIVERSE</span> world! <br></br>Diverse people, diverse ideas,<br></br> diverse solutions.</h5>
                      <p className='home-subtitle'>At Inclusional, we help you integrate diversity into business.</p>
                    </div>
                    <div className='col-md-6 mobile-none'>
                      <img src="/images/banner_1.svg" className='banner_1 home-images'></img>
                    </div>
                  </div>
                </div>
              </div>

              <div className="carousel-item">
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-6 mobile-display'>
                      <img src="/images/banner_2.svg" className='home-images'></img>
                    </div>
                    <div className='col-md-6'>
                      <p className='home-head'>Diversity .<span className='home-head-highlight-1'> Inclusion .</span> Belonging</p>
                      <p className='active-inclu'></p>
                      <h5 className='home-title'><span className='home-highlight-2'>INCLUSION</span> of diverse people, ideas and solutions leads to endless opportunities. </h5>
                      <p className='home-subtitle'>At Inclusional, we work with you to include more effectively, so you can grow efficiently and sustainably. </p>
                    </div>
                    <div className='col-md-6 mobile-none'>
                      <img src="/images/banner_2.svg" className='banner_2 home-images'></img>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="carousel-item">
                <div className='container'>
                  <div className='row'>
                    <div className='col-md-6 mobile-display'>
                      <img src="/images/banner_3.svg" className='home-images'></img>
                    </div>
                    <div className='col-md-6'>
                      <p className='home-head'>Diversity . Inclusion . <span className='home-head-highlight-1'>Belonging</span></p>
                      <p className='active-belon'></p>
                      <h5 className='home-title'>
                      When diversity is truly included, <span className='home-highlight-3'>BELONGING</span> begins, creating something bigger, and the whole ecosystem thrives.
                      </h5>
                      <p className='home-subtitle'>At Inclusional, we facilitate belonging, so people, businesses, and economies prosper.</p>
                    </div>
                    <div className='col-md-6 mobile-none'>
                      <img src="/images/banner_3.svg" className='home-images'></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </main>
        </section>

        <section id="about" className='inclusional slanted-div bgcolor1 style1'>
          <div className='container'>
            <div className='row'>
              <p className='inclusional-sub-title'>OUR SHARED VISION</p>
              <h1 className='inclusional-title'>What is Inclusional?</h1>
              <h2 className='inclusional-para'>We are a tech-led, boutique consultancy that partners with you on your unique journey from diversity to inclusion to belonging (DIB).</h2>
              <img src="/images/inclusional_mob.svg" className='flow-img mobile-display'></img>
              <img src="/images/inclusional_web.svg" className='flow-img mobile-none'></img>
            </div>

            <div className='row'>
              <h3 className='map-title'>Diversity-Inclusion-Belonging growth map</h3>
              <img src="/images/inclusional.svg" className="inclusional-img"></img>
            </div>
          </div>
        </section>

        <section id="offer" className='sticky-container offer'>
          <main>
            <div id="demo1" class="carousel slide" data-bs-ride="carousel" data-interval="10000">
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <div class="offering-section offer-green">
                    <div class='container'>
                      <div class='row'>
                        <div class='col-md-5'>
                          <img src="/images/offering_1.svg" class='offer-slider-img' />
                        </div>
                        <div class='col-md-7 offer-text'>
                          <p class='offer-slide-title'>OUR OFFERINGS (1/3)</p>
                          <p class='offer-slide-3'></p>
                          <p class='offer-slide'></p>
                          <p class='offer-slide'></p>
              
                          <h5 class='offer-slider-heading'>For Safe Workplaces</h5>
              
                          <p class='offer-slider-para'>Inclusion begins when we build safe workplaces for everyone. </p>
              
                          <p class='offer-slider-para'>Our <span class='offer-text-decorate'>Safe Workplaces e-courses</span> in English and Hindi: </p>
                            <ul class="offer-slider-para">
                              <li>Creates shared responsibility between employees and employer</li>
                              <li>Ensuring 100% employee coverage</li>
                              <li>Testing awareness of the mandatory POSH act</li>
                            </ul>
              
                          <p class='offer-slider-para'>We also offer a copy-righted <span class='offer-text-decorate'>training for the Internal Committee</span> that focuses on prevention and not just redressal
                          </p>
              
                          <p class='offer-slider-para'>Our <span class='offer-text-decorate'>in-person POSH and culture workshops</span> are designed to take the mandated POSH law from 'just compliance' to 'a crucial first step for an inclusion strategy that delivers RoI</p>
              
                          <img src="/images/tag.png" class='offer-tag' />
              
                        </div>
                      </div>
                    </div> 
                  </div>          
                </div>

                <div class="carousel-item">
                  <div  class="offering-section offer-blue">
                    <div class='container'>
                      <div class='row'>
                        <div class='col-md-5'>
                          <img src="/images/offering_2.svg" class='offer-slider-img' />
                        </div>
                        <div class='col-md-7 offer-text'>
                          <p class='offer-slide-title'>OUR OFFERINGS (2/3)</p>
                          <p class='offer-slide'></p>
                          <p class='offer-slide-3'></p>
                          <p class='offer-slide'></p>
                          <h5 class='offer-slider-heading'>For Diverse, Inclusive, <br></br>Belonging-filled Workplaces </h5>
              
                          <p class='offer-slider-para'>DIB when done right is a strong business growth lever, just like customer-centricity or digital transformation</p>
              
                          <p class='offer-slider-para'>To achieve this, it is crucial that every employee is personally invested in DIB and that happens when they understand how DIB benefits them at an individual level</p>
              
                          <p class='offer-slider-para'>Our engaging,  <span class='offer-text-decorate'>first-of-a-kind “DIB” e-course</span>  brings everyone on board by making a business and career strategy case for it using data, logic, and an everyone-friendly non-righteous, pragmatic approach to DIB</p>
              
                          <p class='offer-slider-para'>Our customised, interactive, <span class='offer-text-decorate'>in-person learning and development (L&D)</span> workshops are designed to help you turn DIB into a business imperative by building consensus and capability within your organisation for DIB</p>
                        </div>
                      </div>
                    </div>
                  </div>          
                </div>

                <div class="carousel-item">
                  <div  class="offering-section offer-pink">
                    <div class='container'>
                      <div class='row'>
                        <div class='col-md-5'>
                          <img src="/images/offering_3.svg" class='offer-slider-img' />
              
                        </div>
                        <div class='col-md-7 offer-text'>
                          <p class='offer-slide-title'>OUR OFFERINGS (3/3)</p>
                          <p class='offer-slide'></p>
                          <p class='offer-slide'></p>
                          <p class='offer-slide-3'></p>
              
                          <h5 class='offer-slider-heading'>For Thriving Workplaces</h5>
                          <p class='offer-slider-para'>
                            The journey from diversity to inclusion to belonging and finally thriving is neither straightforward nor easy. And this journey is unique to every organisation.
                          </p>
                          <p class='offer-slider-para'>Our customised approach towards  diagnosis and consultancy focuses on:
                            <ul class="offer-slider-para">
                              <li>Identifying the most efficient levers for effective change</li>
                              <li>Designing and implementing creative, effective, sustainable strategies for change</li>
                              <li>Creating a culture that enables every stakeholder as well as the organisation to thrive</li>
                            </ul>
                          </p>
                          <br></br>
                        </div>
                      </div>
                    </div>
                  </div>         
                </div>
              </div>

              <button class="carousel-control-prev" type="button" data-bs-target="#demo1" data-bs-slide="prev">
                <span class="carousel-control-prev-icon"></span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#demo1" data-bs-slide="next">
                <span class="carousel-control-next-icon"></span>
              </button>
          </div>
          </main>
        </section>

        {/* Posh */}
        <div class='posh'>
          <div class='container'>
            <div class='row posh-center'>
              <div class='col-md-6 mobile-display'>
                <img src="/images/ncw.png" className='posh-img'></img>
        
              </div>
              <div class='col-md-6'>
                <p class='posh-partner'>KNOWLEDGE PARTNERS</p>
                <h2 class='posh-title'>NCW Knowledge Partners </h2>
                <h2 class='posh-title'>for POSH</h2>
                <p class='posh-para'>
                  <span class="font-weight-600">INCLUSIONAL</span>, part of Jet Social Impact (JSI) is the knowledge partner for the <span class="font-weight-600">National Commission for Women (NCW)</span> on POSH (Prevention of Sexual Harassment Act)
                </p>
                <p class='posh-para'>
                  As a part of the knowledge partnership, we have been helping improve awareness and compliance around POSH by:
                  <ul class='posh-para'>
                    <li>Training Government employees on POSH</li>
                    <li>Delivering POSH awareness workshops for corporates in West and South India</li>
                    <li>Creating social media campaigns</li>
                  </ul>
                </p>
                <p class='posh-para'>
                  POSH Awareness Workshops for the corporates happening on  <br></br><br></br>
                  <span class="font-weight-600">2<sup>nd</sup>  February 2024 in Pune</span>
                </p>
                <a href="https://bit.ly/POSHReimaginedBengaluru" class="btn btn-primary mb-10" target="_blank">Register Now</a>
        
              </div>
              <div class='col-md-6 mobile-none'>
                <img src="/images/ncw.png" className='posh-img'></img>
              </div>
            </div>
          </div>
        </div>

        {/* Team */}
        <div className='team'>
          <div className='container'>
            <p className='team-subtitle'>THE TEAM</p>
            <h3 className='team-title'>Meet the core Inclusional team</h3>
            <div className='row paddingLR-100'>
              <div className='col-md-4'>
                <div className='card team-space'>
                  <img className="card-img-top team_1" src="/images/Priyanka_Agrawal.png" alt="Card image" />
                  <div className="card-body">
                    <h4 className="card-title">Priyanka Agrawal</h4>
                    <p className="card-text">Head, Inclusional​</p>
                    <p className='card-details'>CA, ex-Director: KPMG, ​​DIB-Certified ​​(TISS, UPenn,ESSEC), REBT trained​​</p>
                    <p className='mb-0'><a href="https://www.linkedin.com/in/priyanka-chaturvedi-agrawal-%F0%9F%8C%88she-her-hers-1a35921/" target='_blank'><img src="/images/linkedin.png" className="social-icon"></img></a></p>
                  </div>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='card team-space'>
                  <img className="card-img-top team_2" src="/images/Jayati_Doshi.png" alt="Card image" />
                  <div className="card-body">
                    <h4 className="card-title">Jayati Doshi</h4>
                    <p className="card-text">Head, Culture Consulting​</p>
                    <p className='card-details'>Harvard, FLAME,​ Entrepreneur​ DIB & culture change specialist​ Coach & consultant to global orgs​</p>
                    <p className='mb-0'><a href="https://www.linkedin.com/in/jayati-doshi/" target='_blank'><img src="/images/linkedin.png" className="social-icon"></img></a></p>
                  </div>
                </div>
              </div>

              <div className='col-md-4'>
                <div className='card'>
                  <img className="card-img-top team_3" src="/images/Nidhi_Shah.png" alt="Card image" />
                  <div className="card-body">
                    <h4 className="card-title">Nidhi Shah</h4>
                    <p className="card-text">Head, Design & CX​</p>
                    <p className='card-details'>Srishti Design School,​​ User experience designer & visual communicator​​</p>
                    <p className='mb-0'><a href="https://www.linkedin.com/in/nidhimshah/" target='_blank'><img src="/images/linkedin.png" className="social-icon"></img></a></p>
                  </div>
                </div>
              </div>
            </div>

            <p className='footer-team'>With a strong backing of<a href="https://www.jetsynthesys.com/" target="_blank"><img src="/images/jet_name.png" className='team-jet-logo'></img></a>ecosystem</p>
          </div>
        </div>

        {/* Clients */}
        <div className='vertical-section customers'>
          <div className='container'>
            <p className='customer-subtitle'>CLIENT REVIEWS</p>
            <h3 className='customer-title'>Hear What Our Valued Partners Say</h3>

            <div className="row">
              <div className="col-md-12 mb-4">

                <Carousel>
                  <div>
                    <div className='col-md-12 text-center'>
                      <img src="/images/client_img1.png" className="client-image"></img>
                      <h3 className='client-name'>Priya Bakhtiani</h3>
                      <p className='client-designation'>Startup HR Specialist, Cirkla Inc.</p>
                    </div>
                    <div className='row paddingLR-40'>
                      <div className='col-md-1'>
                        <img src="/images/colon_1.png" className='colon_img mobile-none'></img>
                      </div>
                      <div className='col-md-10'>
                        <p className='client-comment'>For Prevention of Sexual Harassment in the workplace, we recently partnered with Inclusional, Jet Social Impact <a href='https://www.linkedin.com/company/jetsynthesys/' target='_blank' className='text-black'>JetSynthesys Pvt. Limited</a> for POSH compliance, and I highly recommend them.They made the entire process so seamless and uncomplicated- Simple checklist of end-to-end compliance steps and a well-drafted customizable policy.- Informative and highly interactive in-person training for the IC- Easy to follow, quick yet insightful and well-crafted online training for employees- Employee result analysis, especially the before-and-after scores of employees, clearly demonstrating the effectiveness of the course.I highly recommend this training for its utility and impact. Kudos to <a href='https://www.linkedin.com/in/priyanka-chaturvedi-agrawal-she-her-hers-1a35921/' target='_blank' className='text-black'>Priyanka Chaturvedi Agrawal (🌈she/her/hers)</a> for making the entire process so smooth.</p>

                      </div>
                      <div className='col-md-1'>
                        <img src="/images/colon_2.png" className='colon_2_img mobile-none'></img>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className='col-md-12 text-center'>
                      <img src="/images/client_img2.png" className="client-image"></img>
                      <h3 className='client-name'>Harsh Vardhan Passari</h3>
                      <p className='client-designation'>Co-Founder, IBC Consultants</p>
                    </div>
                    <div className='row paddingLR-40'>
                      <div className='col-md-1'>
                        <img src="/images/colon_1.png" className='colon_img mobile-none'></img>
                      </div>
                      <div className='col-md-10'>
                        <p className='client-comment'>Recognizing the unique demands of our hybrid work model at <a href='https://www.linkedin.com/company/consult-ibc/' target='_blank' className='text-black'>IBC Consultants</a>, we reached out to Jet Social Impact (JSI) (by <a href='https://www.linkedin.com/company/jetsynthesys/' target='_blank' className='text-black'>JetSynthesys Pvt. Limited</a>) for assistance with our POSH policy.JSI exceeded our expectations in fostering a culture where safety becomes a shared objective. Their team delivered e-training in both Hindi and English, effectively addressing sensitive topics. The use of gender-neutral animation characters and pre/post-module testing significantly enhanced learning outcomes. JSI's tailored Learning and Development plan, coupled with its pro-bono analytics, ensured cost-effectiveness while meeting our specific needs. Their deep understanding of diversity and inclusion positions them as true experts in the field, offering competitive pricing for their POSH compliance services.I wholeheartedly recommend JSI to my fellow entrepreneurs who want to get their teams compliant with POSH.</p>

                      </div>
                      <div className='col-md-1'>
                        <img src="/images/colon_2.png" className='colon_2_img mobile-none'></img>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className='col-md-12 text-center'>
                      <img src="/images/client_img3.png" className="client-image"></img>
                      <h3 className='client-name'>Hemant Sandu</h3>
                      <p className='client-designation'>Business Head-India, Mace</p>
                    </div>
                    <div className='row paddingLR-40'>
                      <div className='col-md-1'>
                        <img src="/images/colon_1.png" className='colon_img mobile-none'></img>
                      </div>
                      <div className='col-md-10'>
                        <p className='client-comment'>Being a woman leader I understand the challenges faced by women in our male-dominated industry. Thrilled to announce that we recently hosted two-day in-person session on gender sensitization and inclusion in Gurgaon.We explored how power dynamics influence workplaces, specifically linked to gender, and developed empathy to create safe and energized environments. We critically examined our own behaviors, biases, and blind spots to create an opportunity for growth and positive impact. Embedding diversity and inclusion in our DNA is not just a moral imperative but also a strategic advantage in today's business landscape. It enables us to tap into a wider talent pool, foster creativity, innovation, and drive business growth.</p>
                      </div>
                      <div className='col-md-1'>
                        <img src="/images/colon_2.png" className='colon_2_img mobile-none'></img>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className='col-md-12 text-center'>
                      <img src="/images/client_img4.png" className="client-image"></img>
                      <h3 className='client-name'>Deven Parulekar</h3>
                      <p className='client-designation'>Founder, SaffronStays</p>
                    </div>
                    <div className='row paddingLR-40'>
                      <div className='col-md-1'>
                        <img src="/images/colon_1.png" className='colon_img mobile-none'></img>
                      </div>
                      <div className='col-md-10'>
                        <p className='client-comment'>Inclusion is an absolute imperative for a business like ours. Post Covid19, we were eager to partner with a POSH consultant who understands not just our business and its challenges but also the diverse workforce that makes <a href='https://www.linkedin.com/company/saffronstays/' target='_blank' className='text-black'>SaffronStays</a> what it is! Inclusion under "Jet Social Impact (JSI)" delivered more than it promised.We have together leveraged the POSH law of the land to create a safe workplace for all. I particularly loved their gender-neutral fingerprint inspired animation characters. The testing process (at the beginning and at the end of the module) shows how the e-course improves pre and post course learning outcomes. The team understands diversity and inclusion like true subject matter experts and will create a plan that works for your organisation.</p>

                      </div>
                      <div className='col-md-1'>
                        <img src="/images/colon_2.png" className='colon_2_img mobile-none'></img>
                      </div>
                    </div>
                  </div>

                  <div>
                    <div className='col-md-12 text-center'>
                      <img src="/images/client_img5.png" className="client-image"></img>
                      <h3 className='client-name'>Ekta R Iyer</h3>
                      <p className='client-designation'>Assistant Manager, MACE</p>
                    </div>
                    <div className='row paddingLR-40'>
                      <div className='col-md-1'>
                        <img src="/images/colon_1.png" className='colon_img mobile-none'></img>
                      </div>
                      <div className='col-md-10'>
                        <p className='client-comment'>To create and spread awareness, <a href='https://www.linkedin.com/company/mace-group/' target='_blank' className='text-black'>Mace</a> BU INDIA had a wondeful training session arranged on Gender Sensitivity. It was an exemplary platform to share individual takes, experiences, views and ideas. I would wholeheartedly like to thank <a href='https://www.linkedin.com/in/hemant-sandu-2973617/' target='_blank' className='text-black'>Hemant Sandu</a> and the entire team for making this a grandeur. Adding to this, I would also extend my heartfelt gratitue to <a href='https://www.linkedin.com/in/priyanka-chaturvedi-agrawal-she-her-hers-1a35921/' target='_blank' className='text-black'>Priyanka Chaturvedi Agrawal (🌈she/her/hers)</a> and team for all the wise and insighful words.</p>
                      </div>

                      <div className='col-md-1'>
                        <img src="/images/colon_2.png" className='colon_2_img mobile-none'></img>
                      </div>


                    </div>

                  </div>
                </Carousel>

              </div>
            </div>

            <div className='row text-center'>

              <div className='col-md-12 mb-4'>
                <h2 className='text-center my-5 fw-bold'>Trusted by trade bodies and businesses</h2>
                <p className='text-center mb-4 text-gray fw-semibold'>Preferred POSH compliance partners for</p>
                <div className='d-flex justify-content-center align-items-center mb-5'>
                <div className='client-logo col-lg-2 col-6 p-0'> <img src="/images/image_10.png" className='img-fluid px-3' alt="logo" /></div>
                <div className='client-logo col-lg-2 col-6 p-0'>  <img src="/images/idma-logo.svg" className='img-fluid px-3' alt="logo" /></div>
                </div>
              </div>

              <p className='text-gray fw-semibold mb-5'>From startups to brands, we have plenty of stories to share</p>

              <div className='d-flex justify-content-start align-items-center flex-wrap mb-5'>
                <div className='client-logo col-lg-3 col-6'><img src="/images/Fractal-Logo.svg" alt="logo" className='img-fluid px-3' /></div>
                <div className='client-logo col-lg-3 col-6'><img src="/images/MullenloweLintas-Logo-1.svg" alt="logo" className='img-fluid px-3' /></div>
                <div className='client-logo col-lg-3 col-6'><img src="/images/image_6.png" alt="logo" className='img-fluid px-3' /></div>
                <div className='client-logo col-lg-3 col-6'><img src="/images/image_4.png" alt="logo" className='img-fluid px-3' /></div>
                <div className='client-logo col-lg-3 col-6'><img src="/images/image_7.png" alt="logo" className='img-fluid px-3' /></div>
                <div className='client-logo col-lg-3 col-6'><img src="/images/IBC's_LOGO.png" alt="logo" className='img-fluid px-3' /></div>
                <div className='client-logo col-lg-3 col-6'><img src="/images/image_2.png" alt="logo" className='img-fluid px-3' /></div>
                <div className='client-logo col-lg-3 col-6'><img src="/images/image_9.png" alt="logo" className='img-fluid px-3' /></div>
                <div className='client-logo col-lg-3 col-6'><img src="/images/image_8.png" alt="logo" className='img-fluid px-3' /></div>
                <div className='client-logo col-lg-3 col-6'><img src="/images/Rajpath-Group-Logo-1.svg" alt="logo" className='img-fluid px-3' /></div>
                <div className='client-logo col-lg-3 col-6'><img src="/images/image_1.png" alt="logo" className='img-fluid px-3' /></div>
              </div>

              </div>
            
            {/*<div className="testimonial__inner">
              <div className="testimonial-slider">
                <div className="testimonial-slide">
                  <div className="testimonial_box">
                    <div className="testimonial_box-inner">
                      <div className="testimonial_box-top">
                        <div className="testimonial_box-img">
                          <img src="/images/client_11.png" alt="client-logo" className='client-logo-1' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="testimonial-slide">
                  <div className="testimonial_box">
                    <div className="testimonial_box-inner">
                      <div className="testimonial_box-top">
                        <div className="testimonial_box-img">
                          <img src="/images/client_12.png" alt="client-logo" className='client-logo-2' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="testimonial-slide">
                  <div className="testimonial_box">
                    <div className="testimonial_box-inner">
                      <div className="testimonial_box-top">
                        <div className="testimonial_box-img">
                          <img src="/images/client_13.png" alt="client-logo" className='client-logo-3' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="testimonial-slide">
                  <div className="testimonial_box">
                    <div className="testimonial_box-inner">
                      <div className="testimonial_box-top">
                        <div className="testimonial_box-img">
                          <img src="/images/client_14.png" alt="client-logo" className='client-logo-4' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="testimonial-slide">
                  <div className="testimonial_box">
                    <div className="testimonial_box-inner">
                      <div className="testimonial_box-top">
                        <div className="testimonial_box-img">
                          <img src="/images/client_15.png" alt="client-logo-5" className='client-logo-5' />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>*/}

          </div>
        </div>

        <section id="news" className='news-media'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-12'>
                <p class="team-subtitle">INCLUSIONAL HIGHLIGHTS</p>
                <h3 class="team-title">News & Media</h3>
              </div>
            </div>
            <div className='row'>
              {currentImages.map((element) => (
                <div key={element.id} className='col-md-4 mb-4'>
                  <div className='card team-space m-3'>
                    <a href={element.captionUrl} target='_blank'> <img className="card-img-top team_1 bg-white p-2" src={element.imgurl} alt={element.caption} /> </a>
                    <div className="card-body">
                      <p><a href={element.captionUrl} target='_blank' className="card-text text-decoration-none">{element.caption}</a></p>
                      <p><a href={element.captionUrl} target='_blank' className='card-details text-black fw-bold text-decoration-none'>{element.description}</a>​</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className='d-flex justify-content-center align-items-center w-100 mt-4'>
                <div className="pagination ">
                <button onClick={() => handlePrevPage()} disabled={currentPage === 1}>
                  <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'rotate(180deg)' }}>
                    <path d="M0.840088 1.41L5.42009 6L0.840088 10.59L2.25009 12L8.25009 6L2.25009 0L0.840088 1.41Z" fill="#949494" />
                  </svg>
                </button>
                  {Array.from({ length: totalPages }, (_, index) => index + 1).map((page) => (
                    <button
                      key={page}
                      onClick={() => handlePageChange(page)}
                      className={currentPage === page ? 'active' : ''}
                    >
                      {page}
                    </button>
                  ))}
                <button
                  onClick={() => handleNextPage()}
                  disabled={currentPage === totalPages}>
                  <svg width="9" height="12" viewBox="0 0 9 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.840088 1.41L5.42009 6L0.840088 10.59L2.25009 12L8.25009 6L2.25009 0L0.840088 1.41Z" fill="#949494" />
                  </svg>
                </button>
                </div>
              </div>
          </div>
        </section>

        {/* Contact Us */}
        <section id="contact" className='vertical-section contact-us'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-7 pt-20 mobile-mb'>
                <p className='contact-para'>Let’s Connect</p>
                <h1 className='contact-title text-white'>Let us work with you to expand your
                  scope of possibilities through DIB</h1>
                <img src="/images/contact_us.png" className='contact-img'></img>
              </div>

              <div className='col-md-1'></div>

              <div className='col-md-4'>
                {!showSuccess ? (<div className='contact-form'>
                  <form onSubmit={handleSend}>
                    <div className='lets-connect'>
                      <div className="form-group mb-10">
                        <label>Name*</label>
                        <input type="text" placeholder="Name" value={name} onChange={(e) => setName(e.target.value)} className="form-control" required />
                      </div>

                      <div className="form-group mb-10">
                        <label>Contact Number*</label>
                        <input type="text" placeholder="Contact Number" className="form-control" value={mobile_no} onChange={handleMobileChange} required />
                        {mobileError && <p className="error">{mobileError}</p>}

                      </div>

                      <div className="form-group mb-10">
                        <label>Work Email*</label>
                        <input type="text" placeholder="Work Email" className="form-control" value={email} onChange={handleEmailChange} required />
                        {emailError && <p className="error">{emailError}</p>}
                      </div>

                      <div className="form-group mb-10">
                        <label>Organisation Name*</label>
                        <input type="text" placeholder="Organisation Name" value={organisation} onChange={(e) => setOrganisation(e.target.value)} className="form-control" required />
                      </div>

                      <div className="form-group mb-10">
                        <label>Message</label>
                        <textarea className="form-control" placeholder="Type your message here" value={message} onChange={(e) => setMessage(e.target.value)} rows="2"></textarea>
                      </div>

                      <div className="g-recaptcha text-center">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_DATA_SITEKEY} 
                            badge="bottomright"
                            onChange={onChange}
                          />
                      </div>

                      <div className="form-group mt-10">
                        <button className="btn btn-primary btn_color mb-10" type="submit">Send Inquiry</button>
                        <p className="contact-note">By proceeding you agree to our <a href="/term" target='_blank' className="contact-term">Terms and Conditions</a> and <a href="/term" target='_blank' className="contact-term">Privacy Policy</a></p>
                      </div>
                    </div>
                  </form>
                </div>) :
                  (<div className="success-msg">
                    {/* <img src="/images/Rectangle.svg"></img> */}
                    {/* <Lottie options={defaultOptions} /> */}
                    <h3 className='text-center '>Thank you!</h3>
                    <p className='text-center'>We’ve received your inquiry and will be contacting you via email shortly.</p>
                    <p className='contact-reform' onClick={handleClick}>Submit another inquiry</p>
                  </div>)}
              </div>
            </div>
          </div>
        </section>

        {/* footer */}
        <div className='footer'>
          <div className='container'>
            <div className='row'>
              <div className='d-flex justify-content-between align-items-center'>
                <div className='social-media d-md-flex d-sm-none justify-content-md-start justify-content-sm-between w-50'>
                  <div>
                  <p>
                    <a href="/privacy" target='_blank' className='footer-link'>Privacy Policy</a>
                    <a href="/term" target='_blank' className='footer-link'>Terms & Conditions</a>
                  </p>
                  <p>
                    <a href="https://www.linkedin.com/company/inclusional/" target='_blank'><img src="/images/linkedin.png" className="social-icon"></img></a>
                    <a href="https://twitter.com/_inclusional/" target='_blank'><img src="/images/twitter.png" className="social-icon"></img></a>
                    <a href="https://www.instagram.com/inclusional/" target='_blank'><img src="/images/instagram.png" className="social-icon"></img></a>
                    <a href="https://www.facebook.com/profile.php?id=100095283414576&is_tour_dismissed=true" target='_blank'><img src="/images/facebook.png" className="social-icon"></img></a>
                  </p>
                  <p className='footer-copyright'>©Inclusional. All Rights Reserved 2024</p>
                  </div>
                  <div>
                    <img src="/images/logo_jsi.png"  className="footer-logo-jsi" />
                  </div>
                </div>
                <div className='logo-media d-flex justify-content-lg-end justify-content-md-between align-items-center w-50'>
                  <img src="/images/jet_name.png" className='me-2 h-auto' width={210} />
                  <img src="/images/logo.png" width={200} className="footer-logo" />
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='mb-footer-link'>
                <p className='social-mr'>
                  <a href="https://www.linkedin.com/company/inclusional/" target='_blank' className='social-link'><img src="/images/linkedin.png" className="mob-icon"></img></a>
                  <a href=" https://twitter.com/_inclusional/" target='_blank' className='social-link'><img src="/images/twitter.png" className="mob-icon"></img></a>
                  <a href="https://www.instagram.com/inclusional/" target='_blank' className='social-link'><img src="/images/instagram.png" className="mob-icon"></img></a>
                  <a href="https://www.facebook.com/profile.php?id=100095283414576&is_tour_dismissed=true" target='_blank' className='social-link'><img src="/images/facebook.png" className="mob-icon"></img></a>
                </p>
              </div>
            </div>

            <div className='row'>
              <div className='mb-footer-link'>
                <p>
                  <a href="/privacy" target='_blank' className='footer-link'>Privacy Policy</a>
                  <a href="/term" target='_blank' className='footer-link ml-10'>Terms & Conditions</a>
                </p>
                <p className='footer-copyright'>©Inclusional. All Rights Reserved 2024</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
